import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'src/utils/formHelpers';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
// import { contactReasonAnswers, moreSpecificReasonAnswers } from '../../utils/contactUs';
import InputField, { requiredClass } from '../InputField';

type PersonalInformationSectionProps = {
  getFieldDataByName: Function;
};

const PersonalInformationSection = ({ getFieldDataByName }: PersonalInformationSectionProps) => {
  const {
    register,
    // watch,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();

  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(formatPhoneNumber(event.target.value));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = value.replace(/[^0-9a-zA-Z]/g, '').replace(/(.{3})/, '$1 ');
    // eslint-disable-next-line no-param-reassign
    event.target.value = formattedValue;
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value, selectionStart } = event.currentTarget;
    if (selectionStart !== null) {
      if (event.key === 'Backspace' && value[selectionStart - 1] === ' ') {
        // Prevent the default backspace behavior
        event.preventDefault();
        // Remove the space and the character before it
        const newValue = value.slice(0, selectionStart - 2) + value.slice(selectionStart);
        // eslint-disable-next-line no-param-reassign
        event.currentTarget.value = newValue;
        handleInputChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };
  return (
    <>
      <Col xs={12} md={6}>
        <InputField
          register={register}
          name="firstName"
          label={getFieldDataByName('first_name')?.title}
          errors={errors}
          required
        />
      </Col>
      <Col xs={12} md={6}>
        <InputField
          register={register}
          name="lastName"
          label={getFieldDataByName('last_name')?.title}
          errors={errors}
          required
        />
      </Col>
      <Col md={12}>
        <InputField
          register={register}
          name="address1"
          label={getFieldDataByName('address_1')?.title}
          errors={errors}
        />
      </Col>
      <Col md={12}>
        <InputField
          register={register}
          name="address2"
          label={getFieldDataByName('address_2')?.title}
          errors={errors}
        />
      </Col>
      <Col xs={12} md={3}>
        <InputField
          register={register}
          name="city"
          label={getFieldDataByName('city')?.title}
          errors={errors}
        />
      </Col>
      <Col xs={12} md={3}>
        <Form.Group controlId="province">
          <Form.Label className={requiredClass}>{getFieldDataByName('province')?.title}</Form.Label>
          <Form.Select
            {...register('province')}
            isInvalid={!!errors.province}
            aria-invalid={!!errors.province}
            aria-label="Select a province"
          >
            <option key="province-default" value="">
              {t('Select')}
            </option>
            {getFieldDataByName('province').options.map((opt: any) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Form.Select>
          {errors.province && (
            <Form.Control.Feedback type="invalid">{errors.province?.message}</Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={3}>
        <InputField
          register={register}
          maxLength="7"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          name="postal_code"
          label={getFieldDataByName('postal_code')?.title}
          errors={errors}
          placeholder="ex: X2X 2X2"
          required
        />
      </Col>

      <Col md={10}>
        <InputField
          register={register}
          name="email"
          label={getFieldDataByName('email_address')?.title}
          placeholder={t('example@mail.com')}
          errors={errors}
          required
        />
      </Col>
      <Col md={10}>
        <Form.Group controlId="phone_number">
          <Form.Label>{t('Phone Number')}</Form.Label>
          <Form.Control
            {...register('phone_number', {
              pattern: {
                value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                message: 'Phone number must be in format: xxx-xxx-xxxx',
              },
            })}
            type="tel"
            value={phoneNumber}
            onChange={handleChange}
            isInvalid={!!errors?.phone_number}
            aria-invalid={!!errors?.phone_number}
            placeholder="ex. 555-123-4567"
            maxLength={12}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone_number && errors.phone_number.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </>
  );
};

export default PersonalInformationSection;
